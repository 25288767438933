import React from 'react';
import { Button, Col, Row, Slider, Segmented, Form } from 'antd';
import { InputCustom } from '../../../../components/InputCustom/inputCustom';
import classes from './buySell.module.scss';
import 'rc-slider/assets/index.css';
import { useState } from 'react';
import Api from '../../../../http/serviceApi';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { toFixedIfNecessary } from '../../../../utils/toFixedIfNecessary';
import { useDispatch } from 'react-redux';
import { SET_USER_TRADEINFO } from '../../../../store/actionTypes';
import { SET_Loading_USER_TRADEINFO } from '../../../../store/actionTypes';
import './buySellAntdStyles.scss';
import { useSnackbar } from 'notistack';
import { useTheme } from '@mui/material';

import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';
import { useTranslation } from 'react-i18next';

const formType = {
  Limit: 'LIMIT',
  Market: 'MARKET',
  StopLimit: 'STOP_LIMIT',
};

const BuySell = (props) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [orderId, setOrderId] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [price_buy, setPrice_buy] = useState(0);
  // const [amount_buy, setAmount_buy] = useState();
  // const [total_buy, setTotal_buy] = useState(0);
  const [availableAmount, setAvailableAmount] = useState('');
  const [selectedForm, setSelectedForm] = useState(formType.Limit);
  const [formBuy] = Form.useForm();
  const [formSell] = Form.useForm();
  const obListItem = useSelector((store) => store.obListItem);
  // const [values, setValues] = useState({
  //   price: '',
  //   amount: '',
  // });
  const [total, setTotal] = useState(0);
  const [sliderValue_buy, setSliderValue_buy] = useState(0);
  const [sliderValue_sell, setSliderValue_sell] = useState(0);
  const [disableBtnBuy, setDisableBtnBuy] = useState(true);
  const [disableBtnSell, setDisableBtnSell] = useState(true);
  const dispatch = useDispatch();

  const colProps = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 12,
    xl: 12,
  };

  useEffect(() => {
    formBuy.setFieldsValue({
      price: obListItem.p,
      limit: obListItem.p,
    });
    formSell.setFieldsValue({
      limit: obListItem.p,
      price: obListItem.p,
    });
  }, [obListItem]);

  useEffect(() => {
    let interval;
    if (props.symbolInfo) {
      getUserAvailableAmount();
      interval = setInterval(getUserAvailableAmount, 15000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [props.symbolInfo]);

  const getUserAvailableAmount = async () => {
    // setLoading(true);

    try {
      const response = await Api.getUserAvailableAmount(
        `${props.symbolInfo?.baseAsset}${props.symbolInfo?.quoteAsset}`
      );
      if (response?.status === 'SUCCESS') {
        setAvailableAmount({
          baseAmount: response.baseAmount,
          quoteAmount: response.quoteAmount,
          lastPrice: response.lastPrice,
          sellPrice:response?.sellPrice,
          buyPrice:response?.buyPrice
        });
      }
    } catch (e) {}
    // setLoading(false);
  };

  const onBuyFormFinish = () => {
    formBuy
      .validateFields()
      .then((values) => {
        debugger;
        const data = {
          price: selectedForm === formType.StopLimit ? values.limit : values.price,
          amount: values.amount,
          side: 'BUY',
          stopPrice: values.stop,
          symbol: props.symbolInfo?.symbol,
          type: selectedForm,
        };
        createOrder(data);
      })
      .catch((errorInfo) => {});
  };

  const onSellFormFinish = () => {
    formSell
      .validateFields()
      .then((values) => {
        debugger;
        const data = {
          price: selectedForm === formType.StopLimit ? values.limit : values.price,
          amount: values.amount,
          side: 'SELL',
          stopPrice: values.stop,
          symbol: props.symbolInfo?.symbol,
          type: selectedForm,
        };
        createOrder(data);
      })
      .catch((errorInfo) => {});
  };

  const createOrder = async (data) => {
    setLoading(true);
    try {
      const response = await Api.createOrder(data);
      if (response?.status === 'SUCCESS') {
        enqueueSnackbar('The order registered successfully', { variant: 'success' });
        setOrderId(response.setOrderId);
        getUserTradeInfo();
      }
    } catch (e) {}
    setLoading(false);
  };

  const getUserTradeInfo = async () => {
    if (props.symbolInfo) {
      dispatch({
        type: SET_Loading_USER_TRADEINFO,
        payload: {
          loading_UserTradeInfo: true,
        },
      });

      const data = {
        startDate: Date.now() - 86400000,
        finishDate: Date.now(),
        symbol: `${props.symbolInfo?.baseAsset}${props.symbolInfo?.quoteAsset}`,
      };

      try {
        const response = await Api.getUserTradeInfo(data);
        if (response?.status === 'SUCCESS') {
          dispatch({
            type: SET_USER_TRADEINFO,
            payload: {
              openTradeInfos: response.openTradeInfos,
              tradeHistoryInfos: response.tradeHistoryInfos,
            },
          });
        }
        console.log('getUserTradeInfo-response: ', response);
      } catch (e) {}

      dispatch({
        type: SET_Loading_USER_TRADEINFO,
        payload: {
          loading_UserTradeInfo: false,
        },
      });
    }
  };

  const onFormTypeChange = (key) => {
    console.log(key)
    setSelectedForm(key);
    formBuy.setFieldsValue({
      price: '',
      amount: '',
      total: '',
    });
    formSell.setFieldsValue({
      price: '',
      amount: '',
      total: '',
    });
    setSliderValue_buy(0);
    setSliderValue_sell(0);
    setDisableBtnBuy(true);
    setDisableBtnSell(true);
  };

  const fillTotalField = (form , buyOrSell) => {
    let price = 0;
    if (selectedForm === formType.Limit) price = form.getFieldValue('price');
    else if (selectedForm === formType.Market) price = buyOrSell ?  buyOrSell === 'buy' ? availableAmount.sellPrice :   availableAmount.buyPrice: availableAmount.lastPrice;
    if (selectedForm === formType.StopLimit) price = form.getFieldValue('limit');

    const amount = form.getFieldValue('amount');
    let sum = toFixedIfNecessary(Number(price && price) * Number(amount && amount), 6);
    if (sum === 0) sum = '';
    form.setFieldsValue({
      total: sum,
    });
  };

  const fillAmountField = (form) => {
    let price = 0;
    if (selectedForm === formType.Limit) price = form.getFieldValue('price');
    else if (selectedForm === formType.Market) price = availableAmount.lastPrice;
    if (selectedForm === formType.StopLimit) price = form.getFieldValue('limit');

    const total = form.getFieldValue('total');
    let sum = toFixedIfNecessary(Number(total && total) / Number(price && price), 6);
    if (sum === 0) sum = '';
    form.setFieldsValue({
      amount: sum,
    });
  };

  const checkTotalFieldForEnableBuyButton = () => {
    const total = formBuy.getFieldValue('total');
    if (total <= availableAmount.quoteAmount && total && total !== '') setDisableBtnBuy(false);
    else setDisableBtnBuy(true);
  };
  const checkTotalFieldForEnableSellButton = () => {
    const amount = formSell.getFieldValue('amount');
    if (amount <= availableAmount.baseAmount && amount && amount !== '') setDisableBtnSell(false);
    else setDisableBtnSell(true);
  };

  const onValuesChange_buy = (e) => {
    fillTotalField(formBuy, 'buy');
    checkTotalFieldForEnableBuyButton();
  };
  const onValuesChange_sell = (e) => {
    fillTotalField(formSell , 'sell');
    checkTotalFieldForEnableSellButton();
  };

  const onTotalChange_buy = (e) => {
    fillAmountField(formBuy);
  };
  const onTotalChange_sell = (e) => {
    fillAmountField(formSell);
  };

  const onPercentSliderChange_buy = (newValue) => {
    setSliderValue_buy(newValue);
    let price = 0;
    if (selectedForm === formType.Limit) price = formBuy.getFieldValue('price');
    else if (selectedForm === formType.Market) price = availableAmount.lastPrice;
    if (selectedForm === formType.StopLimit) price = formBuy.getFieldValue('limit');

    const val = toFixedIfNecessary((availableAmount.quoteAmount * (newValue / 100)) / price, 6);
    formBuy.setFieldsValue({
      amount: val !== 0 ? val : '',
    });
    fillTotalField(formBuy , 'buy');
    checkTotalFieldForEnableBuyButton();
  };
  const onPercentSliderChange_sell = (newValue) => {
    setSliderValue_sell(newValue);
    const val = toFixedIfNecessary(availableAmount.baseAmount * (newValue / 100), 6);
    formSell.setFieldsValue({
      amount: val !== 0 ? val : '',
    });
    fillTotalField(formSell , 'sell');
    checkTotalFieldForEnableSellButton();
  };

  const {t} = useTranslation()
  return (
    <div className={classes.container}>
      <Row>
        <Col {...colProps} style={{ padding: '10px' }}>
          <Form form={formBuy} name='buyForm' onFinish={onBuyFormFinish}>
            <div className={classes.filtersDiv}>
              <Segmented
                style={{
                  backgroundColor: 'transparent',
                  color: theme.palette.mode === 'dark' ? '#A5ADCF' : '#555',
                  marginLeft: '-5px',
                }}
                options={[{value:'LIMIT' , label:t('LIMIT')},{value:'MARKET' , label:t('MARKET')} , {value:'STOP_LIMIT' , label:t('STOP_LIMIT')}]}
                value={selectedForm}
                onChange={onFormTypeChange}
                onMouseOver={(e) => {
                  e.target.style.color = theme.palette.mode === 'dark' ? '#A5ADCF' : '#555';
                }}
                onMouseLeave={(e) => {
                  e.target.style.color = theme.palette.mode === 'dark' ? '#A5ADCF' : '#555';
                }}
              />
            </div>
            <div className={classes.avbl} style={{ color: theme.palette.mode === 'dark' ? '#fff' : '#000' }} onClick={()=>{
              formBuy.setFields([{name:["amount"], value:[availableAmount.quoteAmount] }])
              onValuesChange_buy()
            }}>
              <span>{t("Avbl")}</span>
              <div style={{ display: 'flex' }}>
                <span>{availableAmount.quoteAmount}</span>
                <div style={{ dir: 'rtl', marginLeft: '5px' }}>
                  <span>{props.symbolInfo?.quoteAsset}</span>
                </div>
              </div>
            </div>
            <div className={classes.inputs}>
              {selectedForm === formType.Market && (
                <div className={classes.inputDiv}>
                  <Col span={24}>
                    <div className={classes.floatText}>
                      <span>{props.symbolInfo?.quoteAsset}</span>
                    </div>
                    <Form.Item name='marketPrice'>
                      <InputCustom variant='filled' label='Price' placeHolder='Price' disabled={true} values='Market' />
                    </Form.Item>
                  </Col>
                </div>
              )}
              {selectedForm === formType.Limit && (
                <div className={classes.inputDiv}>
                  <Col span={24}>
                    <div className={classes.floatText}>
                      <span>{props.symbolInfo?.quoteAsset}</span>
                    </div>
                    <Form.Item name='price'>
                      <InputCustom
                        type='Number'
                        label='Price'
                        variant='filled'
                        placeHolder='Price'
                        disabled={selectedForm === formType.Market}
                        onChange={onValuesChange_buy}
                      />
                    </Form.Item>
                  </Col>
                </div>
              )}
              {selectedForm === formType.StopLimit && (
                <div className={classes.inputDiv}>
                  <Col span={24}>
                    <div className={classes.floatText}>
                      <span>{props.symbolInfo?.quoteAsset}</span>
                    </div>
                    <Form.Item name='limit'>
                      <InputCustom variant='filled' type='Number' label='Limit' placeHolder='Limit' />
                    </Form.Item>
                  </Col>
                </div>
              )}
              {selectedForm === formType.StopLimit && (
                <div className={classes.inputDiv}>
                  <Col span={24}>
                    <div className={classes.floatText}>
                      <span>{props.symbolInfo?.quoteAsset}</span>
                    </div>
                    <Form.Item name='stop'>
                      <InputCustom variant='filled' type='Number' label='Stop' placeHolder='Stop' />
                    </Form.Item>
                  </Col>
                </div>
              )}
              <div className={classes.inputDiv}>
                <Col span={24}>
                  <div className={classes.floatText}>
                    <span>{props.symbolInfo?.baseAsset}</span>
                  </div>
                  <Form.Item name='amount'>
                    <InputCustom
                      variant='filled'
                      type='Number'
                      label='Amount'
                      placeHolder='Amount'
                      onChange={onValuesChange_buy}
                    />
                  </Form.Item>
                </Col>
              </div>
            </div>
            <Slider
              className='sliderBuy'
              marks={{ 0: ' ', 25: ' ', 50: ' ', 75: ' ', 100: ' ' }}
              defaultValue={0}
              onChange={onPercentSliderChange_buy}
              value={sliderValue_buy}
            />
            <div className={classes.inputDiv}>
              <Col span={24}>
                <div className={classes.floatText}>
                  <span>{props.symbolInfo?.quoteAsset}</span>
                </div>
                <Form.Item name='total'>
                  <InputCustom
                    variant='filled'
                    type='Number'
                    label='Total'
                    placeHolder='Total'
                    value={total}
                    onChange={onTotalChange_buy}
                  />
                </Form.Item>
              </Col>
            </div>
            <Button
              type='primary'
              shape='round'
              block
              className={classes.btnGreen}
              style={{ marginTop: '20px' }}
              loading={loading}
              htmlType='submit'
              disabled={disableBtnBuy}
            >
              {'Buy ' + props.symbolInfo?.baseAsset}
            </Button>
          </Form>
        </Col>

        <Col {...colProps} style={{ padding: '10px' }}>
          <Form form={formSell} name='sellForm' onFinish={onSellFormFinish}>
            <div className={classes.filtersDiv}>
              <span></span>
              <span></span>
              <div>
                <span></span>
              </div>
            </div>
            <div className={classes.avbl} style={{ color: theme.palette.mode === 'dark' ? '#fff' : '#000' }}
                 onClick={()=>{
                   formSell.setFields([{name:["amount"], value:[availableAmount.baseAmount] }])
                   onValuesChange_sell()
                 }}

            >
              <span>{t("Avbl")}</span>
              <div style={{ display: 'flex' }}>
                <span>{availableAmount.baseAmount}</span>
                <div style={{ dir: 'rtl', marginLeft: '5px' }}>
                  <span>{props.symbolInfo?.baseAsset}</span>
                </div>
              </div>
            </div>
            <div className={classes.inputs}>
              {selectedForm === formType.Market && (
                <div className={classes.inputDiv}>
                  <Col span={24}>
                    <div className={classes.floatText}>
                      <span>{props.symbolInfo?.quoteAsset}</span>
                    </div>
                    <Form.Item name='marketPrice'>
                      <InputCustom variant='filled' label='Price' placeHolder='Price' disabled={true} values='Market' />
                    </Form.Item>
                  </Col>
                </div>
              )}
              {selectedForm === formType.Limit && (
                <div className={classes.inputDiv}>
                  <Col span={24}>
                    <div className={classes.floatText}>
                      <span>{props.symbolInfo?.quoteAsset}</span>
                    </div>
                    <Form.Item name='price'>
                      <InputCustom
                        variant='filled'
                        type='Number'
                        label='Price'
                        placeHolder='Price'
                        disabled={selectedForm === formType.Market}
                        onChange={onValuesChange_sell}
                      />
                    </Form.Item>
                  </Col>
                </div>
              )}
              {selectedForm === formType.StopLimit && (
                <div className={classes.inputDiv}>
                  <Col span={24}>
                    <div className={classes.floatText}>
                      <span>{props.symbolInfo?.quoteAsset}</span>
                    </div>
                    <Form.Item name='limit'>
                      <InputCustom variant='filled' type='Number' label='Limit' placeHolder='Limit' />
                    </Form.Item>
                  </Col>
                </div>
              )}
              {selectedForm === formType.StopLimit && (
                <div className={classes.inputDiv}>
                  <Col span={24}>
                    <div className={classes.floatText}>
                      <span>{props.symbolInfo?.quoteAsset}</span>
                    </div>
                    <Form.Item name='stop'>
                      <InputCustom variant='filled' type='Number' label='Stop' placeHolder='Stop' />
                    </Form.Item>
                  </Col>
                </div>
              )}
              <div className={classes.inputDiv}>
                <Col span={24}>
                  <div className={classes.floatText}>
                    <span>{props.symbolInfo?.baseAsset}</span>
                  </div>
                  <Form.Item name='amount'>
                    <InputCustom
                      variant='filled'
                      type='Number'
                      label='Amount'
                      placeHolder='Amount'
                      onChange={onValuesChange_sell}
                    />
                  </Form.Item>
                </Col>
              </div>
            </div>
            <Slider
              className='sliderSell'
              marks={{ 0: ' ', 25: ' ', 50: ' ', 75: ' ', 100: ' ' }}
              defaultValue={0}
              onChange={onPercentSliderChange_sell}
              value={sliderValue_sell}
            />
            <div className={classes.inputDiv}>
              <Col span={24}>
                <div className={classes.floatText}>
                  <span>{props.symbolInfo?.quoteAsset}</span>
                </div>
                <Form.Item name='total'>
                  <InputCustom
                    variant='filled'
                    type='Number'
                    label='Total'
                    placeHolder='Total'
                    onChange={onTotalChange_sell}
                  />
                </Form.Item>
              </Col>
            </div>
            <Button
              type='primary'
              shape='round'
              block
              className={classes.btnRed}
              style={{ marginTop: '20px' }}
              loading={loading}
              disabled={disableBtnSell}
              htmlType='submit'
            >
              {'Sell ' + props.symbolInfo?.baseAsset}
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default BuySell;
